// Define any common css which we can use throughout the app

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.outer {
  width: 100%;
  margin: 0 auto;
}

.input-container {
  margin: 50px auto;
  padding: 30px;
  height: 400px;
  width: 300px;
  border-radius: 10px;
  border: 2px solid #fafafa;
  background-color: white;
  box-shadow: 4px 8px #fafafa;
}
input {
  width: 80%;
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.central-container {
  margin: 0 auto;
  width: 50vw;
}

.central-container-wide {
  margin: 0 auto;
  width: 90vw;
}

.central-container-wide-fixed {
  position: fixed;
  margin: 50px auto;
  width: 90vw;
}

.user {
  background-color: lightgray;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content a {
  // color: white;
  padding: 12px 16px;
  display: block;
  background-color: gray;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  z-index: 1;
  background-color: #f9f9f9;
}

.block {
  display: block !important;
}

.hidden {
  display: none !important;
}
